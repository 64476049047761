import axios from './axios.js'

const authService = {
    async getTokenWithFaktura(code) {
        let body = {
            code: code,
            redirectUri : window.location.href.split('?')[0]
        }
        let result = await axios.post("auth/token", body);
        return result;
    },
     Authorization() {
        let token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        const now = new Date().getTime();
        let expiresIn = localStorage.getItem('ExpiresIn') ?? 1;
        if (now > expiresIn) {
            localStorage.removeItem('token');
            localStorage.removeItem('ExpiresIn');
            return false;
          }
          return token;
    },
     SaveToken(token) {
        localStorage.setItem('token',token); 
    },
    SetExpiredToken(second) {
        const now = new Date().getTime();
        const expiryTime = now + second * 1000;
        localStorage.setItem("ExpiresIn", expiryTime);
    },
    RemoveToken() {
        localStorage.removeItem('token');
        localStorage.removeItem('ExpiresIn');
    }
}

export default authService;