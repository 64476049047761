<template>
  <Alert-component></Alert-component>
      <div v-if="getProcess()" class="modal fade show d-flex justify-content-center align-items-center" style="z-index: 1056; background-color: rgb(255 255 255 / 39%);" >

        <div class="position-absolute " style="z-index: 9999; ">        
          <div class="loader"></div>
        </div>
      </div>
  <router-view/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AuthService from './services/authService';
import axios from './services/axios';
import AlertComponent from './components/alertComponent.vue';
export default {
  methods: {
    ...mapGetters([
      'getProcess'
    ]),
    
    ...mapActions([
      'getCommittentInfo_api'
    ])
  },
  components: {
    AlertComponent
  },
  watch: {
    async '$route.fullPath'(to, from) {
      if (this.$route.query.code) {
        let result = await AuthService.getTokenWithFaktura(this.$route.query.code);
        if (result.status == 200) {
          if (result.data.accessToken) {
              AuthService.SaveToken(result.data.accessToken);
              AuthService.SetExpiredToken(result.data.expiresIn);
                axios.defaults.headers.Authorization = result.data.accessToken ;
                }
            this.$router.replace({ name: 'home' });
        }
      }
    }
  },
  beforeMount() {
    bootstrap.Toast.Default.delay = 2400
  }
}
</script>
<style>

</style>
