<template>
    <div class="modal fade landing-page" id="EmployedModal" tabindex="-1" aria-labelledby="employedModalLabel"
      aria-hidden="true">
      <div class=" modal-dialog   modal-dialog-centered ">
        <div class="modal-content " style="border-radius: 8px;">
          <div class="modal-body p-4 h-100">
            <div class="d-flex justify-content-between mb-3">
              <h5 class="modal-title" id="employedModalLabel">Добавить работника</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div>
              <div class=" d-flex flex-wrap justify-content-between User-info-component">
                <div class=" col-12 py-3 px-3">
                  <label for="pinfl">ПИНФЛ</label>
                  <input id="pinfl" class="input-custom" type="text" :disabled="employed.pinfl?.length" v-model="PINFL">
                  <p class="text-danger mt-1"v-if="( this.checkValidation  && validate.isPinfl(this.PINFL)) || isCorrectPinfl"> Пожалуйста, введите правильно</p>
                </div>
                <div class=" col-12 py-3 px-3">
                  <label for="firstName">ФИО</label>
                  <input id="firstName" disabled class="input-custom" type="text" :value="SurName + ' ' + Name + ' ' + MiddleName" >
                </div>
              </div>
              <div class=" d-flex flex-wrap justify-content-between User-info-component">
                <div class=" col-12 py-3 px-3">
                  <label for="firstName">Дата рождения</label>
                  <input id="firstName" class="input-custom" type="date" v-model="Birthday">
                </div>
                <div class=" col-12 py-3 px-3">
                  <label for="egovActivityDate">Комитент до</label>
                  <input id="egovActivityDate" class="input-custom" type="date" v-model="egovActivityDate">
                </div>
                <div class=" col-12 py-3 px-3">
                  <label for="gender">Пол</label>
                  <select id="gender" class="form-select" v-model="Gender">
                    <option value=1 selected>Мужской</option>
                    <option value=2>Женский</option>
                  </select>
                </div>
              </div>
              <div class=" d-flex flex-wrap justify-content-between User-info-component">
                <div class=" col-12 py-3 px-3">
                  <label for="phoneNumber">Номер телефона</label>
                  <input id="phoneNumber" class="input-custom" type="text" v-model="PhoneNumber">
                  <p class="text-danger mt-1" v-if="(this.PhoneNumber !='+998' || this.checkValidation ) && validate.isPhoneNumber(this.PhoneNumber)"> Пожалуйста, введите правильно</p>
                </div>
                <div class=" col-12 py-3 px-3">
                  <label for="card">Банковская карта</label>
                  <input id="card" class="input-custom" type="text" v-model="BankCard">
                  <p class="text-danger mt-1"  v-if="validate.isCard(this.BankCard)"> Пожалуйста, введите правильно</p>
                </div>
              </div>
              <div v-if="isUpdate" class=" d-flex flex-wrap justify-content-end User-info-component">
                <div class="col-12 py-3 px-3">
                  <label for="status">Статус</label>
                  <select id="status" class="form-select" v-model="Status">
                    <option value=0 selected>Активен</option>
                    <option value=1>Не активен</option>
                  </select>
                </div>
              </div>
            </div>
            <div class=" col-12 py-3 px-3 d-flex justify-content-end align-items-end">
              <div>
                <button data-bs-toggle="modal" id="EmployeCloseBtn" data-bs-target="#EmployedModal"
                  class="default-btn secondary-btn ">Отмена</button>
              </div>
              <div>
                <button type="submit" @click="sendRequest()"  class="btn default-btn frame-btn-secondary">Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import validation from '../services/validation.js'
export default {
    data() {
        return {
            Name: '',
            MiddleName: '',
            SurName:'',
            PINFL:'',
            Birthday:'',
            Gender:1,
            PhoneNumber:'+998',
            BankCard:'',
            egovActivityDate: null,
            validate: validation,
            checkValidation: false,
            isCorrectPinfl: false,
            committentId: 0,
            Status: 0
        }
    },
    props: {
        employed: {
            required : true
        }
    },
    computed: {
        isUpdate() {
            return this.employed.id ?? false;
        },
        isValid() {
          return !(this.validate.isName(this.Name) || this.validate.isName(this.SurName) //|| this.validate.isName(this.MiddleName) 
          || this.validate.isPhoneNumber(this.PhoneNumber) 
          || this.validate.isCard(this.BankCard)
        );
        },
        committentInfo() {
          return this.getCommittentInfo();
        }
    },
    methods: {
      ...mapActions ([
        'createEmployed_api',
        'updateEmployed_api',
        'getAllEmployeds_api',
        'getNameEmployeeByPinf_api'
      ]),
      ...mapMutations([
        'setErrorMessage'
      ]),
      ...mapGetters([
        'getCommittentInfo'
      ]),
      async sendRequest() {
        this.checkValidation = true;
        if (this.isValid) {
          let requestBody = {
            firstName: this.Name,
            lastName: this.SurName,
            middleName: this.MiddleName,
            gender: this.Gender,
            birthDate: this.Birthday?.length > 0 ? this.Birthday : null,
            committentId: this.committentId,
            pinfl: this.PINFL,
            egovActivityDate: this.egovActivityDate?.length > 0 ? this.egovActivityDate : null,
            card: this.BankCard?.length > 0 ? this.BankCard : null,
            phone : this.PhoneNumber
          }

          if (this.isUpdate) {
            requestBody.id = this.employed.id;
            debugger
            requestBody.status = parseInt(this.Status);
            await  this.updateEmployed_api(requestBody).then((response) => {
              this.setErrorMessage("Самозанятость успешно сохранена")
              let modal = document.getElementById('EmployeCloseBtn');
              if (response.status == 200) {
                modal.click();
              }
            }).catch((e) => {
              this.setErrorMessage(e);
            });
          }
          else {
            await  this.createEmployed_api(requestBody).then((response) => {
              this.setErrorMessage("Самозанятость успешно сохранена")
              let modal = document.getElementById('EmployeCloseBtn');  
              if (response.status && response.status == 200) {
                modal.click();
                this.Name= '';
                this.MiddleName= '';
                this.SurName='';
                this.PINFL='';
                this.Birthday='';
                this.Gender=1;
                this.PhoneNumber='+998';
                this.BankCard='';
                this.egovActivityDate = null;
                this.checkValidation = false;
              }
              else if (response.status && response.status == 409) {
                let message = {
                        message: "Самозанятость уже существует.",
                        type: 'danger'
                    }
                    this.setErrorMessage(message);
              }
              else {
                let message = {
                        message: response.Title,
                        type: 'danger'
                    }
                    this.setErrorMessage(message);
              }
            }).catch((e) => {
              this.setErrorMessage(e.response.Title);
            });;
          }
          this.$emit('change-page', this.$route.query.page ?? 1);

        }
      }
    },
    watch: {
      'committentInfo' (to, from) {
        this.committentId = this.committentInfo?.id ?? null;
      },
      'employed'(to,from) {
            this.Name = this.employed.firstName ? this.employed.firstName : ""
            this.MiddleName = this.employed.middleName ? this.employed.middleName : ""
            this.SurName = this.employed.lastName ? this.employed.lastName : ""
            this.PINFL = this.employed.pinfl ? this.employed.pinfl : ""
            this.Birthday = this.employed.birthDate?  this.employed.birthDate.split('T')[0] : ""
            this.Gender = this.employed.gender ?? 1
            this.PhoneNumber = this.employed.phone ? this.employed.phone : "+998"
            this.egovActivityDate = this.employed.egovActivityDate ? this.employed.egovActivityDate.split('T')[0] : null
            this.BankCard = this.employed.card ? this.employed.card : ""
            this.Status = this.employed.status ? parseInt(this.employed.status): 0
            this.checkValidation =  false
      },
      async 'PINFL' (to,from) {
        if (!/^[0-9]+$/.test(to) && to != "") {
          this.PINFL = from;
        }
        if (!this.employed.pinfl?.length) {
          if (to?.length == 14 && from?.length != 15) {
            this.isCorrectPinfl = false
            await this.getNameEmployeeByPinf_api(this.PINFL).then((response) => {
              if (response.data && response.data.firstName) {
                this.Name = response.data.firstName;
                this.SurName = response.data.lastName;
                this.MiddleName = response.data.middleName;
              }
              else {
                this.isCorrectPinfl = true;
                
              }
            })
          }
          else if (to?.length > 14) {
            
            this.PINFL = from;
          }
          else if (from?.length == 14 && to?.length == 13) {
            this.Name = '';
              this.SurName = '';
              this.MiddleName = '';
          }
        }else {
          this.isCorrectPinfl = false
        }
      }
    },
    mounted() {
      this.committentId = this.getCommittentInfo()?.id ?? null;
    }
}
</script>