import axios from "axios";
import authService from "./authService";
axios.defaults.baseURL = process.env.VUE_APP_API_URL + 'api/';
let token = localStorage.getItem('token');
if (token ?? false) {
    axios.defaults.headers.Authorization =  token ?? '';

}
axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access - 401');
        authService.RemoveToken();
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );
export default axios